import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";

import Button from "components/UI/Button";
import ActionDialog from "components/common/ActionDialog";
import { ReactIf } from "components/common/ReactIf";
import { logoWhite, logoutIcon, plus, logoSingle } from "assets/icons";
import TextWithTooltip from "components/common/TextWithTooltip";
import { useIntlHelper } from "hooks";

import ClientContext from "../Context";
import usePageLogic from "./usePageLogic";
import TabList from "./TabList";

import "./styles.scss";
import { isEmpty } from "helpers";
import clsx from "clsx";

const Drawer: React.FC = () => {
  const { getIntlMessage } = useIntlHelper();
  const {
    drawerTabs,
    hiddenTabs,
    setDrawerTabs,
    setHiddenTabs,
    isEditMode,
    isSuperAdmin,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
  } = useContext(ClientContext);

  const {
    setOpenLogoutDialog,
    userName,
    logout,
    openLogoutDialog,
    addDashboard,
    collapseSidebar,
  } = usePageLogic();

  const getInitials = (userName: string): string => {
    if (!userName) return "";

    const nameParts = userName.trim().split(" ");

    const firstInitial = nameParts[0]?.[0] || "";
    const secondInitial = nameParts.length > 1 ? nameParts[1]?.[0] || "" : "";

    return (firstInitial + secondInitial).toUpperCase();
  };

  return (
    <div
      className={`client-drawer ${
        isSidebarCollapsed ? "collapse-sidebar" : ""
      }`}
    >
      <div className="drawer-container">
        <div
          className={clsx("ctn-logo", {
            "ctn-logo-collapsed": isSidebarCollapsed,
          })}
        >
          {isSidebarCollapsed ? (
            <img
              className="intuilize-logo intuilize-logo-single"
              src={logoSingle}
              alt="logo-light"
            />
          ) : (
            <img className="intuilize-logo" src={logoWhite} alt="logo-light" />
          )}
        </div>

        <ReactIf value={isEditMode && isSuperAdmin}>
          <Button
            startIcon={<img src={plus} alt="add icon" />}
            variant="outlined"
            onClick={() => addDashboard.setOpen(true)}
            className="add-new-dashboard-btn"
            fullWidth
          >
            {getIntlMessage("new_dashboard")}
          </Button>
        </ReactIf>

        <nav className="client-navigation">
          <ul>
            <TabList
              data={drawerTabs}
              positionsBefore={0}
              setDataOrder={setDrawerTabs}
            />
          </ul>

          <ReactIf value={isSuperAdmin && !isEmpty(hiddenTabs)}>
            <h3 className="hidden-title">
              {getIntlMessage("hidden_dashboard")}
            </h3>
            <ul>
              <TabList
                data={hiddenTabs}
                positionsBefore={drawerTabs.length}
                setDataOrder={setHiddenTabs}
              />
            </ul>
          </ReactIf>
        </nav>
      </div>

      <ReactIf value={!isEditMode}>
        <div
          className={
            !isSidebarCollapsed
              ? "floating-collapse-button"
              : "floating-collapse-buttonCollapsed"
          }
          onClick={() => collapseSidebar()}
        >
          {isSidebarCollapsed ? "›" : "‹"}
        </div>
      </ReactIf>

      <ReactIf value={!isEditMode}>
        <div className="drawer-tab profile-tab">
          <TextWithTooltip
            text={isSidebarCollapsed ? getInitials(userName) : userName}
            className="tab-content"
          />
          <IconButton
            aria-label="logout"
            onClick={() => setOpenLogoutDialog(true)}
            className="logout-icon"
          >
            <img src={logoutIcon} alt="logout" />
          </IconButton>
        </div>
      </ReactIf>

      <ActionDialog
        onClose={() => setOpenLogoutDialog(false)}
        onApply={logout}
        open={openLogoutDialog}
        ids={{
          dialogTitle: "logOut",
          dialogContent: "confirmLogOut",
        }}
      />
      {addDashboard.el}
    </div>
  );
};

export default Drawer;
