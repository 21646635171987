import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import TextWithTooltip from "components/common/TextWithTooltip";
import { Dashboard } from "types";

import ClientContext from "../../Context";

type DrawerListProps = {
  data: Dashboard[] | [];
  positionsBefore: number;
  setDataOrder: (v: Dashboard[] | []) => void;
};

type TabProps = {
  item: Dashboard;
  index: number;
  dashboardId: string;
  companyId: string;
  isDisabled: boolean;
};

const reorder = (
  list: Dashboard[] | [],
  startIndex: number,
  endIndex: number
): Dashboard[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result as Dashboard[];
};

const Tab: React.FC<TabProps> = ({
  item,
  index,
  dashboardId,
  companyId,
  isDisabled,
}) => {
  const navigate = useNavigate();

  const navigateTo = () => navigate(`/user/company/${companyId}/${item.id}`);

  return (
    <Draggable
      draggableId={item.id as string}
      index={index}
      isDragDisabled={!isDisabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          onClick={navigateTo}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <li
            className={clsx("drawer-tab d-flex h-center-content", {
              "drawer-tab-active": dashboardId === item.id,
            })}
          >
            <TextWithTooltip text={item.name} className="tab-content" />
          </li>
        </div>
      )}
    </Draggable>
  );
};

const DrawerList: React.FC<DrawerListProps> = ({
  data,
  positionsBefore,
  setDataOrder,
}) => {
  const { companyId, dashboardId, isEditMode, isSuperAdmin, onOrderChange } =
    useContext(ClientContext);

  const isDragAndDropDisabled = useMemo(
    () => isEditMode && isSuperAdmin,
    [isEditMode, isSuperAdmin]
  );

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newTabsOrder = reorder(
      data,
      result.source.index,
      result.destination.index
    );

    onOrderChange(
      result.draggableId,
      positionsBefore + result.destination.index
    );

    setDataOrder(newTabsOrder);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {data.map((tab: Dashboard, index: number) => (
              <Tab
                item={tab}
                index={index}
                key={tab.id}
                companyId={companyId}
                dashboardId={dashboardId}
                isDisabled={isDragAndDropDisabled}
              />
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DrawerList;
