import { createApi } from "@reduxjs/toolkit/query/react";
import { FieldValues } from "react-hook-form";

import { IFormikValues } from "interfaces/auth";
import { transformToFormData } from "helpers";
import baseQuery from "services/baseQuery";

import { onSignInFailure, onSignInSuccess } from "@redux/reducers/auth.slice";
import {
  onPasswordRecoveryInSuccess,
  onPasswordRecoveryFailure,
} from "@redux/reducers/recovery.slice";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (build) => ({
    signIn: build.mutation<IFormikValues, FieldValues>({
      query: (data) => {
        return {
          url: `auth/login`,
          method: "POST",
          body: transformToFormData(data),
          onSuccess: onSignInSuccess,
          onError: onSignInFailure,
        };
      },
    }),
    passwordRecovery: build.mutation<IFormikValues, FieldValues>({
      query: (data) => {
        return {
          url: `auth/password-recovery`,
          method: "POST",
          body: data,
          onSuccess: onPasswordRecoveryInSuccess,
          onError: onPasswordRecoveryFailure,
        };
      },
    }),
    setPassword: build.mutation<IFormikValues, FieldValues>({
      query: ({ data, isNewUser }) => {
        return {
          url: `auth/${
            isNewUser ? "accept-invite" : "password-recovery/set-password"
          }`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useSignInMutation,
  usePasswordRecoveryMutation,
  useSetPasswordMutation,
} = authApi;
