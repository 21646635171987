import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useSignInMutation } from "services/auth/authService";
import { useGetUserDataMutation } from "services/user/user";
import { saveUserTokensInLocalStorage } from "helpers";
import { routes } from "invariants";

export type SignInLogicOptions = {
  isError: boolean;
  errors: string[];
  formHandler: (v: FieldValues) => void;
};

export const usePageLogic = (): SignInLogicOptions => {
  const navigate = useNavigate();

  const [getUserData] = useGetUserDataMutation();

  const [signIn, { isError, error }] = useSignInMutation();

  const formHandler = (values: FieldValues) => {
    signIn(values)
      .then((resp: any) => {
        const { data = {} } = resp;
        if (!resp.hasOwnProperty("error")) {
          saveUserTokensInLocalStorage(data);
          getUserData({});
          navigate(routes.adminCompanies);
        }
      })

      .catch((e) => {
        console.error("Sign-in failure:", e);
      });
  };

  return {
    isError,
    formHandler,
    errors: (error as any)?.data?.errors || [],
  };
};
